 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="用户姓名">
				<el-input class="el_input" v-model="form.name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="用户手机">
				<el-input class="el_input" v-model="form.tel" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="推荐人手机">
				<el-input class="el_input" v-model="form.agent_tel" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="身份证号">
				<el-input class="el_input" v-model="form.id_card_num" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="实名认证">
				<el-select class="el_input" v-model="form.real_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未认证" value="1"></el-option>
					<el-option label="已通过" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="用户状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="正常" value="1"></el-option>
					<el-option label="停用" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="用户性别">
				<el-select class="el_input" v-model="form.sex" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="男" value="1"></el-option>
					<el-option label="女" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="user_ser">查询</el-button>
				<el-button type="success" @click="open_add_view">添加</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small"  v-loading="list.loading">
				<el-table-column width="100" label="ID" prop="id"></el-table-column>
				<el-table-column label="注册时间" prop="creat_time_text"></el-table-column>
				<el-table-column label="电话号码" prop="tel"></el-table-column>
				<el-table-column label="真实姓名" prop="name"></el-table-column>
				<el-table-column label="实名状态" prop="real_status_text"></el-table-column>
				<el-table-column label="账户状态" prop="status_text"></el-table-column>

				<!-- 操作行 -->
				<el-table-column fixed="right" label="操作" width="190">
					<template slot-scope="scope">

						<!-- 停用/启用 -->
						<el-button v-if="scope.row.status==1" @click="edit_sub({id:scope.row.id,status:2})" size="mini" type="danger">停用</el-button>
						<el-button v-if="scope.row.status==2" @click="edit_sub({id:scope.row.id,status:1})" size="mini" type="success">启用</el-button>

						<!-- 其他 -->
						<el-button @click="user_info_view_open(scope.row)" size="mini" type="primary">详情</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination
			class="margin-top-xl"
			@size-change="page_size_change"
			@current-change="page_current_change"
			:current-page="page.p"
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num"
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper"
		></el-pagination>

		<!-- 用户详情 -->
		<userInfo
			:user_info="user_info.data"
			:is_show="user_info.is_show"
			@list_refresh="get_page_data"
		></userInfo>

	</div>
</template>

<script>
	import userInfo from './user_info.vue'
	export default {
		components:{
			userInfo
		},
		data() {
			return {

				//搜索条件
				form: {
					name:'',//姓名
					tel:'',//电话
					agent_tel:'',//是否可用
					id_card_num:'',//身份证号
					real_status:'',//身份证号
					status:'',//状态
					sex:'',//性别
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//图片弹出层
				user_info:{
					data:{},
					is_show:0,
				},
			}
		},
		created() {

			//获取可用搜索参数
			this.get_page_data()
		},
		methods: {

			//打开用户详情
			user_info_view_open(item){

				//置入数据
				this.user_info.data=item

				//打开弹出层
				this.user_info.is_show++
			},
			//关闭用户详情
			user_info_view_close(item){

				//关闭弹出层
				this.user_info.is_show=0;

				//清空数据
				this.user_info.data={}
			},

			//前往添加页面
			open_add_view(){
				this.get_menu_list()
				this.add_para.is_show=true
			},

			//前往修改页面
			open_edit_view(item){
				this.get_menu_list()
				this.edit_para.user_info=item
				this.edit_para.is_show=true
			},

			//搜索
			user_ser(){
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){

				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取后台用户
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'admin_list',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//下单时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//实名状态
							switch(item.real_status){
								case '1':item.real_status_text='未实名';break;
								case '2':item.real_status_text='已实名';break;
							}

							//账户状态
							switch(item.status){
								case '1':item.status_text='正常';break;
								case '2':item.status_text='停用';break;
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//弹出层
	.in_tab_img_item{
		width: 300px;
	}

	//用户修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 134px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>