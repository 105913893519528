<template>
	<el-dialog top="1vh" title="用户详情" width="1050px" :visible.sync="is_show_in_page">
		<el-form class="form_ordinary" label-width="100px">

			<div class="big_tit" style="margin-top:0px;">基本数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="用户手机" style="width:320px">
					<el-input class="el_inner_width" v-model="user_info.tel" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="用户姓名" style="width:320px">
					<el-input class="el_inner_width" v-model="user_info.name" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="身份证号" style="width:320px">
					<el-input class="el_inner_width" v-model="user_info.id_card_num" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">头像/身份证</div>
			<div class="tab1_inner">
				<div class="el_form_item" label="" style="width:32%">
					<div class="img_btn">
						<div v-if="!headimg_src" class="btn">未上传</div>
						<img  v-else :src="headimg_src" class="img" @click="show_img(headimg_src)">
					</div>
					<div class="bottom_text">
						头像 {{headimg_status_text}}
						<el-button @click="img_pass('headimg')" size="mini" type="text" style="margin-left:15px">通过</el-button>
						<el-button @click="img_unpass('headimg')" size="mini" type="text" style="color:red;">驳回</el-button>
					</div>
				</div>
				<div class="el_form_item" style="width:32%;margin-left:1%">
					<div class="img_btn">
						<div v-if="!id_card_img1_src" class="btn">未上传</div>
						<img  v-else :src="id_card_img1_src" class="img" @click="show_img(id_card_img1_src)">
					</div>
					<div class="bottom_text">
						身份证正面 {{id_card_img1_status_text}}
						<el-button @click="img_pass('id_card_img1')" size="mini" type="text" style="margin-left:15px">通过</el-button>
						<el-button @click="img_unpass('id_card_img1')" size="mini" type="text" style="color:red;">驳回</el-button>
					</div>
				</div>
				<div class="el_form_item" style="width:32%;margin-left:1%">
					<div class="img_btn">
						<div v-if="!id_card_img2_src" class="btn">未上传</div>
						<img  v-else :src="id_card_img2_src" class="img" @click="show_img(id_card_img2_src)">
					</div>
					<div class="bottom_text">
						身份证反面 {{id_card_img2_status_text}}
						<el-button @click="img_pass('id_card_img2')" size="mini" type="text" style="margin-left:15px">通过</el-button>
						<el-button @click="img_unpass('id_card_img2')" size="mini" type="text" style="color:red;">驳回</el-button>
					</div>
				</div>
			</div>

		</el-form>

		<el-dialog
			top="2vh"
			width="90%"
			title="查看大图"
			:modal="false"
			:visible.sync="img.is_show"
		>
			<img :src="img.src" style="width:100%">
		</el-dialog>
	</el-dialog>
</template>
<script>
	export default {
		props:{
			is_show:Number,//是否显示
			user_info:Object,//用户数据
		},
		data() {
			return {

				//页面是否显示
				is_show_in_page:false,

				//头像状态
				headimg_status_text:'',

				//头像
				headimg_src:'',

				//身份证正面状态
				id_card_img1_status_text:'',

				//身份证正面
				id_card_img1_src:'',

				//身份证反面状态
				id_card_img2_status_text:'',

				//身份证反面
				id_card_img2_src:'',
				
				//大图
				img:{
					is_show:false,
					src:''
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.page_init();
					this.is_show_in_page=true;
				}
			},
		},
		methods: {

			//图片不通过
			img_unpass(img){

				let form={
					mod:'app_user',
					ctr:'admin_edit',
					id:this.user_info.id,
				}

				switch(img){
					case 'headimg':
						var confirm_content="用户头像";
						form.headimg_status=4;
						break;
					case 'id_card_img1':
						var confirm_content="身份证正面";
						form.id_card_img1_status=4;
						break;
					case 'id_card_img2':
						var confirm_content="身份证反面";
						form.id_card_img2_status=4;
						break;
				}

				//询问
				this.$my.other.confirm({
					content:"确认驳回'"+confirm_content+"'?",
					confirm:()=>{

						//提交
						this.$my.net.req({
							data:form,
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});

								//
								switch(img){
									case 'headimg':this.headimg_status_text="审核未通过";break;
									case 'id_card_img1':this.id_card_img1_status_text="审核未通过";break;
									case 'id_card_img2':this.id_card_img2_status_text="审核未通过";break;
								}

								//通知
								this.$$emit('list_refresh')
							},
						});
					}
				});
			},

			//图片通过
			img_pass(img){

				let form={
					mod:'app_user',
					ctr:'admin_edit',
					id:this.user_info.id,
				}

				switch(img){
					case 'headimg':
						var confirm_content="用户头像";
						form.headimg_status=3;
						break;
					case 'id_card_img1':
						var confirm_content="身份证正面";
						form.id_card_img1_status=3;
						break;
					case 'id_card_img2':
						var confirm_content="身份证反面";
						form.id_card_img2_status=3;
						break;
				}

				//询问
				this.$my.other.confirm({
					content:"确认通过'"+confirm_content+"'?",
					confirm:()=>{

						//提交
						this.$my.net.req({
							data:form,
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});

								//
								switch(img){
									case 'headimg':this.headimg_status_text="审核通过";break;
									case 'id_card_img1':this.id_card_img1_status_text="审核通过";break;
									case 'id_card_img2':this.id_card_img2_status_text="审核通过";break;
								}

								//通知
								this.$emit('list_refresh')
							},
						});
					}
				});
			},

			//页面初始化
			page_init(){

				let user_info=this.user_info

				//大图部分
				this.img.is_show=false
				this.img.src=''

				//头像状态
				switch(user_info.headimg_status){
					case '1':this.headimg_status_text='未上传';break;
					case '2':this.headimg_status_text='已上传审核中';break;
					case '3':this.headimg_status_text='审核通过';break;
					case '4':this.headimg_status_text='审核未通过';break;
				}

				//头像
				this.headimg_src=this.$my.qiniu.make_src('headimg',user_info.headimg)

				//身份证正面状态
				switch(user_info.id_card_img1_status){
					case '1':this.id_card_img1_status_text='未上传';break;
					case '2':this.id_card_img1_status_text='已上传审核中';break;
					case '3':this.id_card_img1_status_text='审核通过';break;
					case '4':this.id_card_img1_status_text='审核未通过';break;
				}

				//身份证正面
				this.id_card_img1_src=this.$my.qiniu.make_src('idcard1',user_info.id_card_img1)

				//身份证反面状态
				switch(user_info.id_card_img2_status){
					case '1':this.id_card_img2_status_text='未上传';break;
					case '2':this.id_card_img2_status_text='已上传审核中';break;
					case '3':this.id_card_img2_status_text='审核通过';break;
					case '4':this.id_card_img2_status_text='审核未通过';break;
				}

				//身份证反面
				this.id_card_img2_src=this.$my.qiniu.make_src('idcard2',user_info.id_card_img2)
			},

			//显示大图
			show_img(src){
				this.img.src=src
				this.img.is_show=true
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
		.img_btn{
			border: 1px solid #bbb;
			border-radius: 5px;
			cursor: pointer;
			padding: 5px;
			height:200px;
			.btn{
				text-align: center;
				line-height: 200px;
				font-size: 14px;
			}

			.img{
				display: block;
				margin:auto;
				max-width: 100%;
				max-height: 200px
			}
		}
		.bottom_text{
			margin-top: 5px;
			text-align: center;
		}
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}
	
	.bottom_btns{
		text-align: center;
		margin-top: 30px;
	}

	.form_ordinary{
		// height: calc(100% - 91px);
		padding: 10px 0;
		overflow-y:auto;
	}
	
</style>